.user-profile {
  padding: 20px;
  font-size: 15px;

  .avatar-icon {
    width: 200px;
    height: auto;
    font-size:200px;
    border-radius: 10px;
  }

  a {
    font-size: 15px;
  }

  .user-email {
    padding: 0;
    md-list-item {
      padding-left: 8px;
      md-icon {
        font-size: 18px;
        line-height: 27px;
      }
    }

    .birthday-block {
      margin-top: 30px;
    }
  }
}

.edit-profile {
  float: right;
}


.edit-user-profile{
  md-checkbox{
    color: #737373;
  }
  .contacts-head{
    font-size: 12px;
    font-weight: bold;
    color: #959aaa;
    margin-bottom: 10px;
  }
}

.icon-member-list {
  margin: 0 !important;
}

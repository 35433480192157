.loader-inline {
  display: inline-block;
  vertical-align: middle;

  .sk-circle {
    width: 20px;
    height: 20px;
    position: relative;

    .sk-child {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;

      &:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: #3F51B5;
        border-radius: 100%;
        animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
      }
    }

    .sk-circle2 {
      transform: rotate(30deg);
      &:before {
        animation-delay: -1.1s;
      }
    }
    .sk-circle3 {
      transform: rotate(60deg);
      &:before {
        animation-delay: -1s;
      }
    }
    .sk-circle4 {
      transform: rotate(90deg);
      &:before {
        animation-delay: -0.9s;
      }
    }
    .sk-circle5 {
      transform: rotate(120deg);
      &:before {
        animation-delay: -0.8s;
      }
    }
    .sk-circle6 {
      transform: rotate(150deg);
      &:before {
        animation-delay: -0.7s;
      }
    }
    .sk-circle7 {
      transform: rotate(180deg);
      &:before {
        animation-delay: -0.6s;
      }
    }
    .sk-circle8 {
      transform: rotate(210deg);
      &:before {
        animation-delay: -0.5s;
      }
    }
    .sk-circle9 {
      transform: rotate(240deg);
      &:before {
        animation-delay: -0.4s;
      }
    }
    .sk-circle10 {
      transform: rotate(270deg);
      &:before {
        animation-delay: -0.3s;
      }
    }
    .sk-circle11 {
      transform: rotate(300deg);
      &:before {
        animation-delay: -0.2s;
      }
    }
    .sk-circle12 {
      transform: rotate(330deg);
      &:before {
        animation-delay: -0.1s;
      }
    }
  }

  @keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
}

.loader-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2500;
  display: block;

  .spinner {
    position: absolute;
    left: 50%;
    top: 50%;

    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;

    > div {
      background-color: #3F51B5;
      height: 100%;
      width: 6px;
      display: inline-block;

      animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .rect2 {
      animation-delay: -1.1s;
    }

    .rect3 {
      animation-delay: -1.0s;
    }

    .rect4 {
      animation-delay: -0.9s;
    }

    .rect5 {
      animation-delay: -0.8s;
    }
  }

  @keyframes sk-stretchdelay {
    0%, 40%, 100% {
      transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1.0);
    }
  }
}
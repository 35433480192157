

.block-items {
  padding: 20px;
  margin: 20px;
  border: 1px solid #e2e2e2;

  .block-items-title {
    font-weight: bold;
    margin-bottom: 15px;
  }

  .block-items-wrap {
    max-height: 200px;
    overflow-y: auto;
  }

  md-button.block-items-button {
    margin: 10px;
  }
}

md-icon.note-icon {
  margin-left: 10px;
  margin-right: 10px;

  &.exist-note {
    color: rgb(28, 156, 23);
  }
}

.textarea-note {
  overflow: auto;
}

table.table-plan {
  width: 100%;
}

.myHandle {
  width: 40px;
  cursor: pointer;
  margin: 0;
}

.plan-items {
  padding: 20px;
  margin: 20px;
  border: 1px solid #e2e2e2;

  .plan-items-title {
    font-weight: bold;
    margin-bottom: 15px;
  }

  .plan-items-icon {
    margin: 0 0 10px 20px;
  }

  .plan-items-sub-title {
    margin: 10px 0;
  }

  .my-row {
    background: #e2e2e2;
    padding: 5px 0;
    border-radius: 5px;
  }

  .item-plan {
    margin: 10px 10px 10px 0;

    md-icon.icon-item-plan {
      margin-left: 0;
      margin-right: 6px;

      &-last {
        margin-right: 38px;
      }

      &-first {
        margin-left: 31px;
      }
    }
  }
}


.user-info {

  width: calc(100% - 34px);

  div {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.a-download {
  cursor: pointer;
  margin: 10px 0;
}

md-dialog md-dialog-content:not([layout=row]) > *:first-child:not(.md-subheader) {
  &.add-song-button {
    margin-top: 20px;
  }
}

md-input-container.md-input-container-category {
  margin: 0;
}

.add-song-line-wrapper {
  border: 1px solid #e0e0e0;
  margin: 12px !important;

  .add-song-line {
    padding: 10px 6px;
    font-size: 18px;
    font-weight: 500;
  }
}

.song-finish {
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 4px;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  margin: 0 10px 0 0;
  cursor: pointer;

  &-1 {
    background: #F44336;
  }

  &-2 {
    background: #FFEB3B;
  }

  &-3 {
    background: #4CAF50;
  }

  &-4 {
    background: #fff;
  }
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.sort-song {
 padding: 30px 12px 0 12px;
}

.add-member-line:hover {
  background: #e2e2e2;
}

.c-ch-tabs {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  flex: 1;

  &__menu {
    background-color: #fbfbfb;
    max-width: 200px;
  }

  &__menu-list {
    padding: 15px 0 15px 0;
    width: 100%;
  }

  %menu-list-item{
    padding: 10px 30px 10px 30px;
  }

  &__menu-list-item {
    @extend %menu-list-item;

    &--active {
      @extend %menu-list-item;
      color: #fff;
      background-color: #4474c9;
    }
  }

  &__content {
    display: block;
    position: relative;
    overflow: auto;
    flex: 1;
  }
}

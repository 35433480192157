.side-nav {
  width: 275px;
  background-color: #f6f6f6;
  overflow: visible;
  display: flex;

  .md-icon-button {
    padding: 0;
    margin: 0;
  }

  .user-info-side {
    background: #c0c9d8;
    padding: 20px;
    color: #585c63;

    .user-block {

      .main-name {
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .logout-button {

      md-icon {
        color: #585c63;
        font-size: 35px;
        width: 35px;
        height: 35px;
        margin-top: 14px;
      }
    }

    .menu-icon {
      color:#fff;
      font-size: 25px;
      line-height: 10px;
    }
  }

  .tabs {
    width: 50px;

    .md-icon-button {
      width: 50px;
      height: 50px;
      border-radius: 0;

      &.selected {
        background-color: #4474c9;

        .tab-icon {
          color: #fff;
        }
      }

      &:not(.selected):hover {
        background-color: #eeeeee;
      }
    }

    .tab-icon {
      color: #888888;
    }
  }

  .nav-tree {
    color: #d4d4d4;
    font-size: 15pt;
    .toggle-button {
      md-icon {
        color: #999da9;
        margin-right: 10px;
      }
    }
    .tree-item {

      &.selected {
        background-color: #C8EBFB;
        color: #335faf;

      }

      &:hover {
        background-color: #f9f9f9;
      }
    }

  }
  .title {
    font-size: 15px;
    font-weight: bold;
    padding: 15px 20px 15px 20px;
    color: #29313C;
  }
  .tree {
    background-color: #fff;
    color: #29313C;

    .ws-tree {
      color: #29313C;

      .tree-item {
        &.selected {
          background-color: #C8EBFB;
          color: #335faf;
        }
      }
    }
  }
}


md-content.tabs-container {
  border-right: 1px solid #EBEBEB;
}

.avatar-wrapper {
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 0;
  position: relative;
  overflow: hidden;
  border: 1px solid #e0e0e0;

  &-min {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    min-width: 30px;
  }

  img {
    width: auto;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0 !important;
  }
}

.avatar, .avatar-icon {
  width: 50px;
  height: 50px;
  font-size: 50px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 0;

  &.icon-min {
    width: 30px;
    height: 30px;
    font-size: 30px;
    margin-left: 10px;
  }
}


.main-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

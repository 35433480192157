.main-admin {
  padding: 20px;
  font-size: 15px;

}

.audit-admin {
  padding: 0;

  table.audit-table {
    width: calc(100% - 24px);
    margin: 20px 12px;

    thead tr th {
      background: #eeeeee;
    }

    thead tr th, tbody tr td {
      padding: 10px;
      border-bottom: 1px solid #e0e0e0;
    }
  }
}

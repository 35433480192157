.ws-pagination {

  &__pages {
    list-style-type: none;

    li {
      display: inline-block;
      border-radius: 2px;
      text-align: center;
      vertical-align: top;
      height: 30px;
      box-sizing: 2px;
    }

    a {
      color: #444;
      font-size: 15px;
      padding: 0 10px;
      line-height: 30px;
      text-decoration: none;
    }
  }

  &__page {
    &--active {
      background-color: #0d47a1;

      a {
        color: #fff;
      }

    }

    &--disabled {
      a {
        color: #9E9E9E;
      }
    }
  }
  &__page--arrow-prev {
    &::after{
      content: '<';
      font-size: 25px;
    }
  }
  &__page--arrow-next {
    &::after{
      content: '>';
      font-size: 25px;
    }
  }
}

.ch-tree{

  .tree-item {
    &.selected {
      background-color: #C8EBFB;
      color: #335faf;
    }
  }

  .element {
    outline: inherit;
    padding: 10px;
    font-size: 15px;
    color: #000;

    .title{
      width: 100%;
    }

  }

  .toggle-button {
    margin-right: 10px;
  }

}

/**
 *  Do not remove the comment below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
// endbower
// injector
@import "all.scss";
// endinjector

.td-button-width {
  width: 145px;
}

/**
 *  Do not remove the comment below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
.main-admin {
  padding: 20px;
  font-size: 15px; }

.audit-admin {
  padding: 0; }
  .audit-admin table.audit-table {
    width: calc(100% - 24px);
    margin: 20px 12px; }
    .audit-admin table.audit-table thead tr th {
      background: #eeeeee; }
    .audit-admin table.audit-table thead tr th, .audit-admin table.audit-table tbody tr td {
      padding: 10px;
      border-bottom: 1px solid #e0e0e0; }

.user-profile {
  padding: 20px;
  font-size: 15px; }
  .user-profile .avatar-icon {
    width: 200px;
    height: auto;
    font-size: 200px;
    border-radius: 10px; }
  .user-profile a {
    font-size: 15px; }
  .user-profile .user-email {
    padding: 0; }
    .user-profile .user-email md-list-item {
      padding-left: 8px; }
      .user-profile .user-email md-list-item md-icon {
        font-size: 18px;
        line-height: 27px; }
    .user-profile .user-email .birthday-block {
      margin-top: 30px; }

.edit-profile {
  float: right; }

.edit-user-profile md-checkbox {
  color: #737373; }

.edit-user-profile .contacts-head {
  font-size: 12px;
  font-weight: bold;
  color: #959aaa;
  margin-bottom: 10px; }

.icon-member-list {
  margin: 0 !important; }

.ch-tree .tree-item.selected {
  background-color: #C8EBFB;
  color: #335faf; }

.ch-tree .element {
  outline: inherit;
  padding: 10px;
  font-size: 15px;
  color: #000; }
  .ch-tree .element .title {
    width: 100%; }

.ch-tree .toggle-button {
  margin-right: 10px; }

.ws-pagination__pages {
  list-style-type: none; }
  .ws-pagination__pages li {
    display: inline-block;
    border-radius: 2px;
    text-align: center;
    vertical-align: top;
    height: 30px;
    box-sizing: 2px; }
  .ws-pagination__pages a {
    color: #444;
    font-size: 15px;
    padding: 0 10px;
    line-height: 30px;
    text-decoration: none; }

.ws-pagination__page--active {
  background-color: #0d47a1; }
  .ws-pagination__page--active a {
    color: #fff; }

.ws-pagination__page--disabled a {
  color: #9E9E9E; }

.ws-pagination__page--arrow-prev::after {
  content: '<';
  font-size: 25px; }

.ws-pagination__page--arrow-next::after {
  content: '>';
  font-size: 25px; }

.loader-inline {
  display: inline-block;
  vertical-align: middle; }
  .loader-inline .sk-circle {
    width: 20px;
    height: 20px;
    position: relative; }
    .loader-inline .sk-circle .sk-child {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0; }
      .loader-inline .sk-circle .sk-child:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: #3F51B5;
        border-radius: 100%;
        animation: sk-circleBounceDelay 1.2s infinite ease-in-out both; }
    .loader-inline .sk-circle .sk-circle2 {
      transform: rotate(30deg); }
      .loader-inline .sk-circle .sk-circle2:before {
        animation-delay: -1.1s; }
    .loader-inline .sk-circle .sk-circle3 {
      transform: rotate(60deg); }
      .loader-inline .sk-circle .sk-circle3:before {
        animation-delay: -1s; }
    .loader-inline .sk-circle .sk-circle4 {
      transform: rotate(90deg); }
      .loader-inline .sk-circle .sk-circle4:before {
        animation-delay: -0.9s; }
    .loader-inline .sk-circle .sk-circle5 {
      transform: rotate(120deg); }
      .loader-inline .sk-circle .sk-circle5:before {
        animation-delay: -0.8s; }
    .loader-inline .sk-circle .sk-circle6 {
      transform: rotate(150deg); }
      .loader-inline .sk-circle .sk-circle6:before {
        animation-delay: -0.7s; }
    .loader-inline .sk-circle .sk-circle7 {
      transform: rotate(180deg); }
      .loader-inline .sk-circle .sk-circle7:before {
        animation-delay: -0.6s; }
    .loader-inline .sk-circle .sk-circle8 {
      transform: rotate(210deg); }
      .loader-inline .sk-circle .sk-circle8:before {
        animation-delay: -0.5s; }
    .loader-inline .sk-circle .sk-circle9 {
      transform: rotate(240deg); }
      .loader-inline .sk-circle .sk-circle9:before {
        animation-delay: -0.4s; }
    .loader-inline .sk-circle .sk-circle10 {
      transform: rotate(270deg); }
      .loader-inline .sk-circle .sk-circle10:before {
        animation-delay: -0.3s; }
    .loader-inline .sk-circle .sk-circle11 {
      transform: rotate(300deg); }
      .loader-inline .sk-circle .sk-circle11:before {
        animation-delay: -0.2s; }
    .loader-inline .sk-circle .sk-circle12 {
      transform: rotate(330deg); }
      .loader-inline .sk-circle .sk-circle12:before {
        animation-delay: -0.1s; }

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }

.loader-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2500;
  display: block; }
  .loader-wrapper .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px; }
    .loader-wrapper .spinner > div {
      background-color: #3F51B5;
      height: 100%;
      width: 6px;
      display: inline-block;
      animation: sk-stretchdelay 1.2s infinite ease-in-out; }
    .loader-wrapper .spinner .rect2 {
      animation-delay: -1.1s; }
    .loader-wrapper .spinner .rect3 {
      animation-delay: -1.0s; }
    .loader-wrapper .spinner .rect4 {
      animation-delay: -0.9s; }
    .loader-wrapper .spinner .rect5 {
      animation-delay: -0.8s; }

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4); }
  20% {
    transform: scaleY(1); } }

.c-ch-tabs {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  flex: 1; }
  .c-ch-tabs__menu {
    background-color: #fbfbfb;
    max-width: 200px; }
  .c-ch-tabs__menu-list {
    padding: 15px 0 15px 0;
    width: 100%; }
  .c-ch-tabs .c-ch-tabs__menu-list-item, .c-ch-tabs .c-ch-tabs__menu-list-item--active {
    padding: 10px 30px 10px 30px; }
  .c-ch-tabs__menu-list-item--active {
    color: #fff;
    background-color: #4474c9; }
  .c-ch-tabs__content {
    display: block;
    position: relative;
    overflow: auto;
    flex: 1; }

tr.ui-state-highlight {
  background: #f7f7f7;
  border: 2px dashed #e1e1e1;
  border-collapse: separate;
  border-radius: 4px;
  height: 61px;
  z-index: 100; }

.ui-sortable-helper {
  opacity: 0.6; }

.oni-table {
  border: none;
  border-collapse: collapse;
  width: 100%; }
  .oni-table td, .oni-table th {
    padding: 12px; }
  .oni-table th {
    padding: 45px 12px 30px 12px; }
  .oni-table thead {
    text-transform: uppercase;
    color: #30a5ea;
    font-size: 10px; }
    .oni-table thead th {
      text-align: left; }
  .oni-table tbody tr.tr-in-payment {
    height: 95px;
    padding: 0; }
    .oni-table tbody tr.tr-in-payment td {
      padding: 0; }
    .oni-table tbody tr.tr-in-payment:hover {
      background: #fff;
      color: rgba(0, 0, 0, 0.870588); }
  .oni-table tbody tr {
    padding: 25px; }
    .oni-table tbody tr ng-md-icon {
      fill: #e6eef4; }
    .oni-table tbody tr:hover {
      background: #57677b;
      color: #fff; }
      .oni-table tbody tr:hover ng-md-icon {
        fill: #58bcf1; }
      .oni-table tbody tr:hover td.td-description {
        color: #4eb7f5; }
      .oni-table tbody tr:hover td.td-sans-light {
        color: #fff; }
      .oni-table tbody tr:hover td.td-sans {
        color: #fff; }
    .oni-table tbody tr td {
      border-bottom: 1px solid #eeeff2; }
      .oni-table tbody tr td .td-first {
        width: 100%;
        margin-top: 11px;
        height: 46px;
        line-height: 46px;
        font-family: SofiaProSemiBold;
        color: #465364;
        font-size: 20px;
        overflow: hidden;
        text-overflow: ellipsis; }
      .oni-table tbody tr td .td-second {
        width: 100%;
        height: 25px;
        font-family: OpenSans-Light;
        color: #57677b;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis; }
        .oni-table tbody tr td .td-second .td-second-circle {
          width: 9px;
          height: 9px;
          border: 2px solid #57c1ff;
          border-radius: 11px;
          margin-top: 5px;
          margin-right: 15px;
          overflow: hidden;
          text-overflow: ellipsis; }
    .oni-table tbody tr td.td-description {
      font-family: SofiaProMedium;
      color: #465364;
      font-size: 19px; }
    .oni-table tbody tr td.td-last {
      border-bottom: 0px solid #eeeff2; }
    .oni-table tbody tr td.td-sans-light {
      font-family: OpenSans-Light;
      color: #465364; }
    .oni-table tbody tr td.td-sans {
      font-family: OpenSans-Light;
      color: #586473; }
    .oni-table tbody tr td.td-address-string {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.oni-transperent-dialog {
  background-color: initial;
  box-shadow: initial; }
  .oni-transperent-dialog .header {
    color: #fff;
    font-size: 55px;
    line-height: 55px;
    padding: 66px 0 66px 66px; }
  .oni-transperent-dialog .content {
    background-color: #fff;
    padding: 50px 0 70px 0; }
  .oni-transperent-dialog .secondary {
    background-color: #f1f5fa;
    padding: 50px 0px 70px 0px; }
  .oni-transperent-dialog .title {
    font-size: 22px;
    line-height: 40px;
    color: #57677b; }
  .oni-transperent-dialog .footerButton {
    padding: 40px;
    background-color: #fff; }
    .oni-transperent-dialog .footerButton button {
      height: 50px;
      border-radius: 50px;
      background-color: #56bffd !important; }

.oni-autocomplete {
  height: 63px;
  margin: 20px 0 20px 0; }
  .oni-autocomplete input {
    height: 63px !important;
    padding-left: 10px;
    padding-right: 10px; }

.oni-autocomplete-for-wrap {
  height: 63px;
  font-family: OpenSans-Light;
  line-height: 63px;
  color: #33353b;
  font-size: 18px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }
  .oni-autocomplete-for-wrap input {
    height: 63px !important;
    font-family: OpenSans-Light;
    line-height: 63px;
    color: #33353b;
    font-size: 18px; }
  .oni-autocomplete-for-wrap input:not(.md-input) {
    height: 63px !important;
    font-family: OpenSans-Light;
    line-height: 63px;
    color: #33353b;
    font-size: 18px;
    padding: 0 10px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }
  .oni-autocomplete-for-wrap md-autocomplete-wrap {
    height: 63px;
    width: 100%;
    line-height: 63px;
    border: 1px solid #e9e9e8;
    font-family: OpenSans-Light;
    color: #33353b;
    font-size: 18px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.table-responsive-vertical {
  padding: 20px 12px;
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 300px; }
  .table-responsive-vertical table {
    margin-bottom: 0;
    min-width: 100%;
    border: 1px solid #e1e1e1; }
    .table-responsive-vertical table thead tr th {
      padding: 10px;
      font-weight: 500;
      font-size: 13px;
      color: #9e9e9e;
      vertical-align: bottom;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      text-transform: uppercase;
      background-color: #f7f7f7;
      text-align: left; }
      .table-responsive-vertical table thead tr th.th-with-input {
        text-align: right; }
    .table-responsive-vertical table tbody tr {
      width: 100%;
      cursor: pointer; }
      .table-responsive-vertical table tbody tr:hover {
        background-color: #f7f7f7; }
    .table-responsive-vertical table tbody tr td {
      padding: 10px; }

.title-block {
  height: 54px;
  padding: 0 12px;
  background: #f9f9f9; }
  .title-block span.title {
    font-weight: bold; }

md-input-container.no-field-input-container {
  margin: 0;
  padding: 0;
  position: relative;
  top: 20px; }

.no-vertical-field {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.md-calendar-scroll-mask {
  overflow: inherit; }

.side-nav {
  width: 275px;
  background-color: #f6f6f6;
  overflow: visible;
  display: flex; }
  .side-nav .md-icon-button {
    padding: 0;
    margin: 0; }
  .side-nav .user-info-side {
    background: #c0c9d8;
    padding: 20px;
    color: #585c63; }
    .side-nav .user-info-side .user-block .main-name {
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .side-nav .user-info-side .logout-button md-icon {
      color: #585c63;
      font-size: 35px;
      width: 35px;
      height: 35px;
      margin-top: 14px; }
    .side-nav .user-info-side .menu-icon {
      color: #fff;
      font-size: 25px;
      line-height: 10px; }
  .side-nav .tabs {
    width: 50px; }
    .side-nav .tabs .md-icon-button {
      width: 50px;
      height: 50px;
      border-radius: 0; }
      .side-nav .tabs .md-icon-button.selected {
        background-color: #4474c9; }
        .side-nav .tabs .md-icon-button.selected .tab-icon {
          color: #fff; }
      .side-nav .tabs .md-icon-button:not(.selected):hover {
        background-color: #eeeeee; }
    .side-nav .tabs .tab-icon {
      color: #888888; }
  .side-nav .nav-tree {
    color: #d4d4d4;
    font-size: 15pt; }
    .side-nav .nav-tree .toggle-button md-icon {
      color: #999da9;
      margin-right: 10px; }
    .side-nav .nav-tree .tree-item.selected {
      background-color: #C8EBFB;
      color: #335faf; }
    .side-nav .nav-tree .tree-item:hover {
      background-color: #f9f9f9; }
  .side-nav .title {
    font-size: 15px;
    font-weight: bold;
    padding: 15px 20px 15px 20px;
    color: #29313C; }
  .side-nav .tree {
    background-color: #fff;
    color: #29313C; }
    .side-nav .tree .ws-tree {
      color: #29313C; }
      .side-nav .tree .ws-tree .tree-item.selected {
        background-color: #C8EBFB;
        color: #335faf; }

md-content.tabs-container {
  border-right: 1px solid #EBEBEB; }

.avatar-wrapper {
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 0;
  position: relative;
  overflow: hidden;
  border: 1px solid #e0e0e0; }
  .avatar-wrapper-min {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    min-width: 30px; }
  .avatar-wrapper img {
    width: auto;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0 !important; }

.avatar, .avatar-icon {
  width: 50px;
  height: 50px;
  font-size: 50px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 0; }
  .avatar.icon-min, .avatar-icon.icon-min {
    width: 30px;
    height: 30px;
    font-size: 30px;
    margin-left: 10px; }

.main-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.top-nav {
  background: #0d47a1 !important; }
  .top-nav .md-toolbar-tools {
    max-height: 100%;
    height: auto; }
    .top-nav .md-toolbar-tools .md-icon-button {
      min-width: 40px; }
  .top-nav__l-wrapper {
    display: block;
    position: relative;
    padding: 10px 0 10px 0; }
  .top-nav__header {
    float: left;
    white-space: nowrap;
    line-height: 40px; }
  .top-nav__actions {
    float: right;
    min-height: 40px; }
  .top-nav button.primary {
    background-color: #4caf50;
    color: #fff; }
    .top-nav button.primary:hover {
      background-color: #4caf50 !important; }

.no-outline {
  outline: none; }
  .no-outline:active, .no-outline:focus {
    outline: none; }

.row-margin *:not(:last-child) {
  margin-right: 10px; }

.info-block__title {
  color: #9E9E9E;
  font-size: 13px; }

.info-block__text {
  margin: 5px 0 10px; }

.c-result--warning, .c-result--success, .c-result--process, .c-result--default, .c-result--flex {
  text-transform: uppercase;
  padding: 3px 6px;
  height: 18px;
  font-size: 11px;
  line-height: normal;
  border-radius: 3px;
  color: #fff;
  white-space: nowrap;
  max-width: 110px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  vertical-align: middle;
  text-align: center; }

.min-margin {
  margin: 0 5px; }

.c-result--warning {
  background-color: #f44336; }

.c-result--success {
  background-color: #4caf50; }

.c-result--process {
  background-color: #ffba36; }

.c-result--default {
  background-color: gray; }

.c-result--flex {
  max-width: 300px;
  background-color: gray; }

.white-gray {
  color: #9E9E9E; }

.linkButton {
  font-size: 15px;
  margin: 10px;
  border-bottom: 1px dotted;
  color: #9e9e9e;
  cursor: pointer; }
  .linkButton:hover {
    color: #3f51b5; }

.ws-tabs md-tabs-wrapper {
  background: #0d47a1; }

.ws-tabs md-tab-item {
  color: #86a3d0; }
  .ws-tabs md-tab-item.md-active {
    color: #fff; }

.ws-tabs md-ink-bar {
  background-color: #4caf50; }

.ws-tabs md-prev-button md-icon, .ws-tabs md-next-button md-icon {
  color: #86a3d0; }

.ws-two-row-autocomplete li {
  line-height: 20px;
  height: auto;
  white-space: normal;
  padding: 10px; }

.ws-two-row-autocomplete .metadata {
  font-size: 12px; }

button.special-marks-button-default {
  color: #b2b2b2;
  background: #fff;
  width: auto;
  height: auto;
  padding: 4px;
  border-radius: 3px;
  border: 1px solid #e1e1e1;
  min-height: 0;
  line-height: 100%;
  min-width: 0;
  font-weight: bold;
  margin: 6px 2px;
  font-size: 12px; }

button.special-marks-button-select, button.special-marks-button-select:hover, button.special-marks-button-default:hover {
  color: #0b48a3;
  background: #fff;
  width: auto;
  height: auto;
  padding: 4px;
  border-radius: 3px;
  border: 1px solid #bcc5e2;
  min-height: 0;
  line-height: 100%;
  min-width: 0;
  font-weight: bold;
  margin: 6px 2px;
  font-size: 12px; }

.chips-input-margin {
  position: relative; }
  .chips-input-margin md-chips-wrap {
    margin-top: 23px;
    margin-bottom: 23px; }
  .chips-input-margin .chips-input-margin-label {
    position: absolute;
    top: 10px;
    left: 0;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px; }

.instruction-wrapper {
  padding: 20px; }
  .instruction-wrapper .instruction-gif {
    float: left;
    margin: 20px 0;
    border: 1px solid #000;
    width: auto;
    max-width: 90%;
    max-height: calc(90vh - 80px); }

.overflow-auto {
  overflow: auto; }

.h4-instruction {
  margin: 40px 0 0 0; }

.p-instruction {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  overflow: auto; }

.main-section {
  overflow-x: auto; }

.main-div {
  min-width: 600px !important; }

.fix-height-80 {
  height: calc(80vh); }

* {
  box-sizing: border-box; }

html, body, div, span, applet, object, iframe, p, h1, h2, h3, h4, h5, h6, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

[role="button"] {
  cursor: pointer; }

p {
  margin: 0 0 10px; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none; }

a:link {
  color: #2196f3;
  text-decoration: none; }

a:visited {
  color: #2196f3; }

a:hover {
  color: #0d47a1;
  text-decoration: underline; }

a:active {
  color: #2196f3; }

md-datepicker {
  background-color: transparent; }

.md-chips .md-chip .md-chip-remove {
  border-radius: 50%;
  background-color: #8C8C8C;
  font-size: 10px;
  width: 16px;
  height: 16px;
  top: 8px;
  right: 8px;
  position: absolute; }
  .md-chips .md-chip .md-chip-remove md-icon {
    color: #e6e6e6;
    width: 100%;
    height: 100%;
    border-radius: 50%; }

ul {
  list-style: none; }

.block-items {
  padding: 20px;
  margin: 20px;
  border: 1px solid #e2e2e2; }
  .block-items .block-items-title {
    font-weight: bold;
    margin-bottom: 15px; }
  .block-items .block-items-wrap {
    max-height: 200px;
    overflow-y: auto; }
  .block-items md-button.block-items-button {
    margin: 10px; }

md-icon.note-icon {
  margin-left: 10px;
  margin-right: 10px; }
  md-icon.note-icon.exist-note {
    color: #1c9c17; }

.textarea-note {
  overflow: auto; }

table.table-plan {
  width: 100%; }

.myHandle {
  width: 40px;
  cursor: pointer;
  margin: 0; }

.plan-items {
  padding: 20px;
  margin: 20px;
  border: 1px solid #e2e2e2; }
  .plan-items .plan-items-title {
    font-weight: bold;
    margin-bottom: 15px; }
  .plan-items .plan-items-icon {
    margin: 0 0 10px 20px; }
  .plan-items .plan-items-sub-title {
    margin: 10px 0; }
  .plan-items .my-row {
    background: #e2e2e2;
    padding: 5px 0;
    border-radius: 5px; }
  .plan-items .item-plan {
    margin: 10px 10px 10px 0; }
    .plan-items .item-plan md-icon.icon-item-plan {
      margin-left: 0;
      margin-right: 6px; }
      .plan-items .item-plan md-icon.icon-item-plan-last {
        margin-right: 38px; }
      .plan-items .item-plan md-icon.icon-item-plan-first {
        margin-left: 31px; }

.user-info {
  width: calc(100% - 34px); }
  .user-info div {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.a-download {
  cursor: pointer;
  margin: 10px 0; }

md-dialog md-dialog-content:not([layout=row]) > *:first-child:not(.md-subheader).add-song-button {
  margin-top: 20px; }

md-input-container.md-input-container-category {
  margin: 0; }

.add-song-line-wrapper {
  border: 1px solid #e0e0e0;
  margin: 12px !important; }
  .add-song-line-wrapper .add-song-line {
    padding: 10px 6px;
    font-size: 18px;
    font-weight: 500; }

.song-finish {
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 4px;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  margin: 0 10px 0 0;
  cursor: pointer; }
  .song-finish-1 {
    background: #F44336; }
  .song-finish-2 {
    background: #FFEB3B; }
  .song-finish-3 {
    background: #4CAF50; }
  .song-finish-4 {
    background: #fff; }

.no-margin-bottom {
  margin-bottom: 0 !important; }

.sort-song {
  padding: 30px 12px 0 12px; }

.add-member-line:hover {
  background: #e2e2e2; }

.td-button-width {
  width: 145px; }

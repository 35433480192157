@mixin dropdown($height) {
    background: #f7f7f7;
    border: 2px dashed #e1e1e1;
    border-collapse: separate;
    border-radius: 4px;
    height: $height;
    z-index: 100;
}

tr.ui-state-highlight {
    @include dropdown(61px);
}

.ui-sortable-helper{
  opacity: 0.6;
}
.top-nav {
  background: #0d47a1 !important;

  .md-toolbar-tools {
    max-height: 100%;
    height: auto;

    .md-icon-button {
      min-width: 40px;
    }
  }

  &__l-wrapper {
    display: block;
    position: relative;
    padding: 10px 0 10px 0;
  }

  &__header {
    float: left;
    white-space: nowrap;
    line-height: 40px;
  }

  &__actions {
    float: right;
    min-height: 40px;
  }

  button.primary {
    background-color: #4caf50;
    color: #fff;

    &:hover {
      background-color: #4caf50 !important;
    }
  }
}

* {
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe, p, h1, h2, h3, h4, h5, h6, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

[role="button"] {
  cursor: pointer;
}

p {
  margin: 0 0 10px; }

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

a:link {
  color: #2196f3;
  text-decoration: none;
}

a:visited {
  color: #2196f3;
}

a:hover {
  color: #0d47a1;
  text-decoration: underline;
}

a:active {
  color: #2196f3;
}

md-datepicker {
  background-color: transparent;
}


// потому что!
.md-chips .md-chip .md-chip-remove {
  border-radius: 50%;
  background-color: #8C8C8C;
  font-size: 10px;
  width: 16px;
  height: 16px;
  top: 8px;
  right: 8px;
  position: absolute;

  md-icon {
    color: #e6e6e6;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

}

ul {
  list-style: none;
}
